<template>
<div>

	<!-- /header -->
	<section class="wrapper bg-soft-primary">
		<div class="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
			<div class="row">
				<div class="col-md-10 col-xl-8 mx-auto">
					<div class="post-header">
						<div class="post-category text-line">
							<a class="hover"
								 rel="category">{{accroche}}</a>
						</div>
						<!-- /.post-category -->
						<h1 class="display-3 mb-4">{{title}}</h1>
						<h5 class="display-7 mb-4">{{subtitle}}</h5>
						<ul class="post-meta mb-5">
							<li v-if="date != ''"
									class="post-date"><i class="uil uil-calendar-alt"></i><span>{{date}}</span></li>
							<li v-if="auteur != ''"
									class="post-author"><a href="#"><i class="uil uil-user"></i><span>{{auteur}}</span></a></li>
							<li v-if="comments !=''"
									class="post-comments"><a href="#"><i class="uil uil-comment"></i><span> {{comments}}</span></a></li>
							<li v-if="like !=''"
									class="post-likes"><a href="#"><i class="uil uil-heart-alt"></i><span> {{like}}</span></a></li>
						</ul>
						<!-- /.post-meta -->

						<ul v-if="xml!=''"
								class="list-unstyled tag-list mb-0">
							<li v-for="(item,ib) in category"
									:key="'b'+ib"><a :href="'#'+item.cat_id[0]"
									 class="btn btn-soft-ash btn-sm   mb-0">{{item.libelle[0] }} </a></li>
						</ul>

						<ul v-if="json!=''"
								class="list-unstyled tag-list mb-0">
							<li v-for="(item,ib) in category"
									:key="'b'+ib"><a :href="'#'+item.reference"
									 class="btn btn-soft-ash btn-sm   mb-0">{{item.libelle }} </a></li>
						</ul>


					</div>
					<!-- /.post-header -->
				</div>
				<!-- /column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->
	<section class="wrapper bg-light">
		<div class="container pb-14 pb-md-16">
			<div class="row">
				<div class="col-lg-10 mx-auto">
					<div class="blog single mt-n17">
						<div class="card">
							<figure class="card-img-top"><img :src="img"
										 alt="" /></figure>

							<div class="card-body">
								<div class="classic-view">
									<article class="post">
										<div class="post-content mb-5">
											<div class=" "
													 v-html="replaceAll('\n','<br/>',contents)" />
											<template>
												<div v-for="(item,ic) in category"
														 :key="'c'+ic"
														 :id="'ff'+ic">
													<blockquote class="fs-lg my-8">
														<p :id="item.cat_id[0]"
															 v-if="xml!=''"> {{item.libelle[0]}} </p>
														<p :id="item.reference"
															 v-if="json!=''"> {{item.libelle}}</p>
													</blockquote>
													<!--begin::Bloc-->
													<div class=" "
															 v-for="(risk,iq) in item.blog"
															 :key="'q'+iq">
														<!--begin::Text-->
														<h4 v-if="xml!=''"
																class="text-green">{{risk.titre[0]}}</h4>
														<h4 v-if="json!=''"
																class="text-green">{{risk.titre}}</h4>
														<p v-if="xml!=''"
															 v-html="replaceAll('\n','<br/>',risk.texte[0].trim())"> </p>
														<p v-if="json!=''"
															 v-html="replaceAll('\n','<br/>',risk.texte.trim())"> </p>
														<!--end::Text-->
													</div>
													<!--end::Block-->
												</div>
											</template>
										</div>
										<!-- /.post-content -->
										<div class="post-footer d-md-flex flex-md-row justify-content-md-between align-items-center mt-8">
											<div>
												<ul v-if="xml!=''"
														class="list-unstyled tag-list mb-0">
													<li v-for="(item,ib) in category"
															:key="'b'+ib"><a :href="'#'+item.cat_id[0]"
															 class="btn btn-soft-ash btn-sm rounded-pill mb-0">{{item.libelle[0] }} </a></li>
												</ul>
											</div>
											<div class="mb-0 mb-md-2">
												<div class="dropdown share-dropdown btn-group">
													<button class="btn btn-sm btn-red rounded-pill btn-icon btn-icon-start dropdown-toggle mb-0 me-0"
																	data-bs-toggle="dropdown"
																	aria-haspopup="true"
																	aria-expanded="false">
														<i class="uil uil-share-alt"></i> Partager </button>
													<div class="dropdown-menu">
														<a class="dropdown-item"
															 :href="'https://twitter.com/share?url='+urlpartage">
															<i class="uil uil-twitter"></i>Twitter</a>
														<a class="dropdown-item"
															 :href="'https://www.facebook.com/sharer/sharer.php?u=file:'+urlpartage"><i class="uil uil-facebook-f"></i>Facebook</a>
														<a class="dropdown-item"
															 :href="'https://www.linkedin.com/sharing/share-offsite/?url='+urlpartage"><i class="uil uil-linkedin"></i>Linkedin</a>
														<a class="dropdown-item"
															 :href="'http://www.reddit.com/submit?url='+urlpartage"><i class="uil uil-reddit-alien-alt"></i>Reddit</a>
														<a class="dropdown-item"
															 :href="'mailto:?subject=&body='+urlpartage"><i class="uil uil-envelope-alt"></i>email</a>
														<a class="dropdown-item"
															 :href="'https://telegram.me/share/url?url='+urlpartage"><i class="uil uil-telegram"></i>Telegram</a>
														<a class="dropdown-item"
															 :href="'whatsapp://send?text='+urlpartage"><i class="uil uil-whatsapp"></i>Whatsapp</a>
													</div>
													<!--/.dropdown-menu -->
												</div>
												<!--/.share-dropdown -->
											</div>
										</div>
										<!-- /.post-footer -->
									</article>
									<!-- /.post -->
								</div>
								<!-- /.classic-view -->
								<hr />
								<div class="author-info d-md-flex align-items-center mb-3"
										 v-if="auteur != ''">
									<div class="d-flex align-items-center">
										<figure class="user-avatar"><img class="rounded-circle"
													 alt=""
													 :src='img_auteur' /></figure>
										<div>
											<h6><a href="#"
													 class="link-dark">{{auteur}}</a></h6>
											<span class="post-meta fs-15">{{fonction}} </span>
										</div>
									</div>
									<div class="mt-3 mt-md-0 ms-auto"
											 style="display:none;">
										<a href="#"
											 class="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0"><i class="uil uil-file-alt"></i> All Posts</a>
									</div>
								</div>
								<!-- /.author-info -->
								<p>{{authorinfo}}</p>
								<nav class="nav social"
										 style="display:none;">
									<a href="#"><i class="uil uil-twitter"></i></a>
									<a href="#"><i class="uil uil-facebook-f"></i></a>
									<a href="#"><i class="uil uil-dribbble"></i></a>
									<a href="#"><i class="uil uil-instagram"></i></a>
									<a href="#"><i class="uil uil-youtube"></i></a>
								</nav>
								<!-- /.social -->



								<!-- /.comment-form -->
							</div>
							<!-- /.card-body -->
						</div>
						<!-- /.card -->
					</div>
					<!-- /.blog -->
				</div>
				<!-- /column -->
			</div>
			<!-- /.row -->
		</div>
		<!-- /.container -->
	</section>
	<!-- /section -->


</div>
</template>


<script>
export default {
	name: 'post1',
	components: {},
	props: {

		date: String,
		auteur: String,
		comments: String,
		like: String,
		texte: String,
		xml: String,
		fonction: String,
		avatar: String,
		authorinfo: String,
		json: String,
	},
	data: () => ({
		urlpartage: '',
		accroche: '',
		title: "",
		img: "",
		subtitle: "",
		img_auteur: "",
		contents: "",
		category: [],
		axios_private: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {

		if (this.xml != "") {
			this.img_auteur = "https://media.berlcoin.fr/avatars/" + this.avatar + ".jpg";
			this.urlpartage = window.document.location.href;
			var xml2js = require('xml2js');
			var extractedData = "";
			var parser = new xml2js.Parser();
			parser.parseString(this.xml, function (err, result) {
				extractedData = result['blogs'];
			});
			this.title = extractedData['titre'][0].trim();
			this.subtitle = extractedData['soustitre'][0].trim();
			this.img = extractedData['illustration'][0].trim();
			this.contents = extractedData['texte'][0].trim();
			this.category = extractedData['category'];
		}
		if (this.json != "") {
			this.opt = [];
			this.opt["debug"] = true
			this.opt["api"] = "webpage";
			this.opt["id"] = this.json;
			this.charge_private();
		}

	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		replaceAll: function replaceAll(recherche, remplacement, chaineAModifier) {
			return chaineAModifier.split(recherche).join(remplacement);
		},
		charge_private() {
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.axios_private = response.data
			})
		},
	},
	watch: {
		axios_private: function () {
			if (this.axios_private != undefined) {
				console.log(this.axios_private)
				this.title = this.axios_private.title;
				this.subtitle = this.axios_private.subtitle;
				this.img = this.axios_private.img;
				this.accroche = this.axios_private.surtitle;
				this.contents = this.axios_private.contents;
				this.category = this.axios_private.category;
			}
			//    this.detail = this.property[0];
		},

	}
}
</script>

<style scoped>
</style>
