<template>
<section class="wrapper bg-light">
  <div class="container py-14 py-md-16">
    <h3 class="display-5  pe-xxl-5"> Investissement direct avant tokenisation </h3>
    <div class="row gy-6">
      <div class="col-lg-4" v-for="(item, index) in france_property">
        <div class="card" @click="select(index)">
          <div class="card-body">
            <h5 class="card-title"> {{item.code_postal}} {{item.ville}} </h5>
            <img :src="'https://media.berlcoin.fr/'+item.ville.toLowerCase()+'.jpg'" style="width:100%" />
            <p class="card-text"><br />

              {{item.adresse}}
              <b style="float:right;text-align:right;" class="text-success">
              {{ (  parseInt(item.taux_1an) +  (  (12 )* parseInt(item.nb_chambre)*parseInt(item.location_chambre)) /  (parseInt(item.prix_achat)+parseInt(item.travaux)+parseInt(item.mobilier)+parseInt(item.reserve)) *100 ).toFixed(2) }} %</b>
            </p>
          </div>
          <!--/.card-footer -->
        </div>
        <!--/.row -->
      </div>
      <!--/column -->
    </div>
    <!--/.row -->
    <div class="row my-6">
      <div class="col-lg-12">

        <div class="card" v-if="selection != -1">
          <div class="card-body">
            <h5 class="card-title"> </h5>

            <p class="card-text">


              <h2 class="fs-15 text-uppercase text-line text-primary mb-3" v-text="detail.categorie" />

              <h3 class="display-5  pe-xxl-5" v-text="detail.ville" />
              <p class="mb-7 " v-text="detail.adresse" />

              <span class="lead mb-3" v-text="detail.offre" />

            <h5 class="card-title my-6"> Foncier </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

              <div class="col">
                <div class="card m-1 p-2">
                  Section cadastrale <b class="text-success"> {{detail.section_cadastrale}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-1 p-2">
                  Parcelle <b class="text-success"> {{detail.parcelle}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-3">
                  Surface de la parcelle <b class="text-success"> {{detail.surface_terrain}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Surface non construite <b class="text-success"> {{detail.surface_constructible}}</b>
                </div>
              </div>



            </div>
            <h5 class="card-title my-6"> Bâtiment </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Construction <b class="text-success"> {{detail.annee_construction}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-1 p-2">
                  Surface habitable <b class="text-success"> {{detail.surface_habitable}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Surface aménagable <b class="text-success"> {{detail.surface_amenagable}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Surface sous sol <b class="text-success"> {{detail.surface_sous_sol}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-1 p-2">
                  Niveau <b class="text-success"> {{detail.nb_niveau}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Murs mitoyens <b class="text-success"> {{detail.mur_mitoyen}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-1 p-2">
                  Pièces <b class="text-success"> {{detail.nb_piece}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-1 p-2">
                  Salle de bain <b class="text-success"> {{detail.nb_salle_bain}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Parking <b class="text-success"> {{detail.places_parking}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Annexes <b class="text-success"> {{detail.batiment_annexes}}</b>
                </div>
              </div>



              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Tout à l'égout <b class="text-success"> {{detail.racccordement_eau_usees}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Diagnostique <b class="text-success"> {{detail.diagnostique}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Etat <b class="text-success"> {{detail.etat}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Piscine <b class="text-success"> {{detail.piscine}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Toiture <b class="text-success"> {{detail.toiture}}</b>
                </div>
              </div>


            </div>
            <h5 class="card-title my-6"> Environnement </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">



              <div class="col">

                <div class="card m-0 p-2" style="height:100%">
                  Vue <b class="text-success"> {{detail.vue}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Qualité relative <b class="text-success"> {{detail.qualite}}</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Luminosite <b class="text-success"> {{detail.luminosite}}</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Calme <b class="text-success"> {{detail.calme}}</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Transport <b class="text-success"> {{detail.transport}}</b>
                </div>
              </div>


            </div>

            <h5 class="card-title my-6"> Marché local </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Croissance annuelle <b style="width:100%;text-align:right;" class="text-success"> {{detail.taux_1an}} %</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Croissance 5 ans <b style="width:100%;text-align:right;" class="text-success"> {{detail.taux_5an}} %</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Tension Immobilière <b style="width:100%;text-align:right;" class="text-success"> {{detail.iti}}</b>
                </div>
              </div>
            </div>


            <h5 class="card-title my-6"> Données financière début d'investissement </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">



              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Estimation <b style="width:100%;text-align:right;" class="text-success"> {{detail.net_vendeur.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Prix m2 <b style="width:100%;text-align:right;" class="text-success"> {{(detail.prix_m2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Estimation mini <b style="width:100%;text-align:right;" class="text-success"> {{detail.bas.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Estimation maxi <b style="width:100%;text-align:right;" class="text-success"> {{detail.haut.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Indice de fiabilité <b style="width:100%;text-align:right;" class="text-success"> {{detail.indice}}</b>
                </div>
              </div>
            </div>


            <h5 class="card-title my-6"> Données financières après remise à neuf </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Estimation <b style="width:100%;text-align:right;" class="text-success"> {{detail.at_net_vendeur.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Prix m2 <b style="width:100%;text-align:right;" class="text-success"> {{(detail.at_prix_m2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Estimation mini <b style="width:100%;text-align:right;" class="text-success"> {{detail.at_bas.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Estimation maxi <b style="width:100%;text-align:right;" class="text-success"> {{detail.at_haut.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Indice de fiabilité <b style="width:100%;text-align:right;" class="text-success"> {{detail.indice}}</b>
                </div>
              </div>

            </div>


            <h5 class="card-title my-6"> Données financières investissement </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Prix acquisition <b style="width:100%;text-align:right;" class="text-success"> {{detail.prix_achat.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Travaux <b style="width:100%;text-align:right;" class="text-success"> {{detail.travaux.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>
              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Mobilier <b style="width:100%;text-align:right;" class="text-success"> {{detail.mobilier.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Réserves <b style="width:100%;text-align:right;" class="text-success"> {{detail.reserve.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Total <b style="width:100%;text-align:right;" class="text-success">
                    {{ (parseInt(detail.prix_achat)+parseInt(detail.travaux)+parseInt(detail.mobilier)+parseInt(detail.reserve)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}

                    .00 €</b>

                </div>
              </div>

            </div>


            <h5 class="card-title my-6"> Rendement </h5>
            <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Nombre de chambre <b class="text-success"> {{detail.nb_chambre}}</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Location / chambre
                  <b style="width:100%;text-align:right;" class="text-success"> {{detail.location_chambre.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Location / mois
                  <b style="width:100%;text-align:right;" class="text-success">
                    {{ (parseInt(detail.nb_chambre)*parseInt(detail.location_chambre)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Location annuelle
                  <b style="width:100%;text-align:right;" class="text-success">
                    {{ (  12*  parseInt(detail.nb_chambre)*parseInt(detail.location_chambre)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}}.00 €</b>
                </div>
              </div>


              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Rendement locatif brut
                  <b style="width:100%;text-align:right;" class="text-success">
                    {{ ((  (12 )* parseInt(detail.nb_chambre)*parseInt(detail.location_chambre)) /  (parseInt(detail.prix_achat)+parseInt(detail.travaux)+parseInt(detail.mobilier)+parseInt(detail.reserve)) *100 ).toFixed(2) }} %</b>
                </div>
              </div>

              <div class="col">
                <div class="card m-0 p-2" style="height:100%">
                  Plus value annuelle <b style="width:100%;text-align:right;" class="text-success"> {{detail.taux_1an}} %</b>
                </div>
              </div>

                            <div class="col">
                              <div class="card m-0 p-2" style="height:100%">
                                Rendement total brut
                                <b style="width:100%;text-align:right;" class="text-success">
                                  {{ (  parseInt(detail.taux_1an) +  (  (12 )* parseInt(detail.nb_chambre)*parseInt(detail.location_chambre)) /  (parseInt(detail.prix_achat)+parseInt(detail.travaux)+parseInt(detail.mobilier)+parseInt(detail.reserve)) *100 ).toFixed(2) }} %</b>
                              </div>
                            </div>



            </div>
          </div>
          <!--/.card-footer -->
        </div>
        <!--/.row -->
      </div>
      <!--/column -->
    </div>
    <h3 class="display-5  pe-xxl-5"> Investissement indirect en token </h3>




    <div class="row gy-6">
      <div class="col-lg-4" v-for="(item, indexb) in berlcoin_property">
        <a class="card" target="_blank" :href="'https://berlcoin.fr/View_property/'+item.path">
          <div class="card-body">
            <h5 class="card-title"> {{item.name}} {{item.ville}} </h5>
            <img :src="'https://media.berlcoin.fr/property/'+item.path+'/1.jpg'" style="width:100%" />
            <p class="card-text"><br />

            <span class="text-secondary">  {{item.address}} </span>
              <b style="float:right;text-align:right;" class="text-success">
                {{ ( parseFloat(item.yield) + parseFloat(item.capital_gain)  ).toFixed(2) }} %</b>
            </p>
          </div>
          <!--/.card-footer -->
        </a>
        <!--/.row -->
      </div>
      <!--/column -->
    </div>
  </div>
  <!-- /.container -->
</section>
<!-- /section -->
</template>



<script>
const eurocurrency = new Intl.NumberFormat('fr-FR',
{
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});


export default
{
  name: 'property',
  components:
  {},
  props:
  {},
  data: () => (
  {
    opt: [],
    axios_private: [],
    france_property: [],
    berlcoin_property:[],
    selection: -1,
    detail: []
  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function()
  {
    this.opt = [];
  //    this.opt["debug"] = true
    this.opt["api"] = "property"
    this.opt["selection"] = "france"
    this.charge_private();
    this.opt["selection"] = "berlcoin"
    this.charge_private();

  },
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed:
  {

  },
  methods:
  {
    select(id)
    {
      this.detail = this.france_property[id];
      if (this.selection != id) this.selection = id;
      else this.selection = -1;
    },
    charge_private()
    {
      this.$store.dispatch('post', this.opt).then(response =>
      {
        if (response != undefined) this.axios_private = response.data
      })
    },
  },
  watch:
  {
    axios_private: function()
    {
  //    console.log(this.axios_private)
      if (this.axios_private != undefined)
        if (this.axios_private.result == "france_property_list")   this.france_property = this.axios_private.data;
        if (this.axios_private.result == "berlcoin_property_list")   this.berlcoin_property = this.axios_private.data;
      //    this.detail = this.property[0];
    },

  }
}
</script>

<style scoped>
</style>
