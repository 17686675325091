<template>
<div>
	<post1 :json="json"
				 accroche="Avantages et méthodes"
				 date=""
				 auteur=""
				 fonction=""
				 avatar=""
				 comments=""
				 like=""
				 xml=""
				 authorinfo="" />
</div>
</template>


<script>
import post1 from "@/components/post1.vue";


export default {
	name: 'sci',
	components: {
		post1
	},
	props: {},
	data: () => ({

		json: "1",
		xml: `
          <?xml version="1.0"?>
            <blogs>
          <titre> Tokeniser une société </titre>
          <soustitre>  (Société Civile Immobilière)</soustitre>
          <illustration>https://media.berlcoin.fr/20945479.jpg</illustration>
                <texte>
                La tokenisation d'un projet immobilier est un processus assez simple qui consiste pour l’essentiel à rédiger un livre blanc et un contrat de droit français.
    Ensuite, le logiciel app.fraction permet de gérer l'ensemble de la tokenisation sans difficulté particulière et permet à la société immobilière de se financer plus facilement, d'être plus attrayante pour les investisseurs, de mobiliser plus de capitaux à de meilleures conditions et de développer son patrimoine immobilier plus rapidement.

              </texte>
          <category>

          <libelle>Préparation </libelle>
          <cat_id>IM</cat_id>


            <blog>
          <reference>R1</reference>
          <titre>Livre Blanc</titre>
          <texte>

          Le libre blanc est le document qui décrit la société immobiliere, son projet initial, sa stratégie d’investissement et ses relations avec les possesseurs de tokens.
  Il décrit aussi l’affectation des ressources générées par ce projet : royalties avec reversement, par indexation, par réinvestissement…
  Il décrit aussi les éléments clés de la tokenisation :  le nombre de token émis, la création future de tokens, leurs affectations et la façon dont les possesseurs de token sont  rémunérés.
  Il est destiné aux investisseurs qui ne contractent pas directement avec la société immobilière mais qui échangent des tokens.

          </texte>
          </blog>

          <blog>
        <reference>R2</reference>
        <titre>Vente</titre>
        <texte>
Autoriser la vente de part sociale sans autorisation préalable : vous devez préciser dans les statuts que les associés acceptent que d'autres associés revendent leur part sociale à des tiers sans que cela ne nécessite une décision en assemblée générale.

        </texte>
        </blog>




          </category>
          <category>
          <libelle>Token</libelle>
          <cat_id>TC</cat_id>

                    <blog>
                    <reference>R3</reference>
                    <titre>Émission de token</titre>
                    <texte>
    La sci va créer des tokens dont le nombre est exactement le même que le nombre de parts sociales.
                    </texte>
                    </blog>

          <blog>
          <reference>R3</reference>
          <titre>Rachat de parts</titre>
          <texte>
La Sci va racheter elle-même toutes les parts sociale sauf une par associé. En contrepartie d'une part sociale rachetée, elle va transférer un token sur le compte de l'associé qui apporte sa part. Au final, la SCI conserve en propre au minimum un token par associé et chaque associé conserve au minimum une part sociale.

          </texte>
          </blog>


          </category>


          <category>

          <libelle>Blockchain</libelle>
          <cat_id>BC</cat_id>

          <blog>
          <reference>R2</reference>
          <titre>  Vente et transfert</titre>
          <texte>
Chaque propriétaire d'un token en dispose à discrétion dans son Wallet et peut donc le vendre ou le transférer à un tiers sur un marché secondaire.
          </texte>
          </blog>


          <blog>
          <reference>R2</reference>
          <titre> Ligne de confiance</titre>
          <texte>
La SCI va créer une ligne de confiance dans la blockchain pour chaque associé qui possède au moins une part sociale.
          </texte>
          </blog>

                <blog>
                <reference></reference>
                <titre>Garanti de rachat</titre>
                <texte>
La Sci garantie le rachat d'un token contre une part sociale à tout associé qui désire récupérer sa part sociale : le token est alors conserver par la sci.

              </texte>
                </blog>



          </category>



          <category>


          <libelle>Gestion</libelle>
          <cat_id>MT</cat_id>




          <blog>
          <reference></reference>
          <titre>Votation</titre>
          <texte>
Avant l'assemblée générale, le gestionnaire de la SCI émet des tokens de vote dans un smart contrat ainsi que les lignes de confiance, chaque associé peut alors valider une transaction de vote au plus tard au moment du vote final. Une fois le vote réalisé, le gestionnaire supprime les lignes de confiance et le vote est clos.
          </texte>
          </blog>



          <blog>
          <reference></reference>
          <titre>Émission ou distribution</titre>
          <texte>
Selon les modalités statutaires, les bénéfices de la SCI sont soit répartis entres associés, soit la SCI conserve les revenus et peut on non emmètre de nouveaux tokens qui sont distribués aux associés. Les revenus locatifs servent dans ce cas à augmenter le capital.
          </texte>
          </blog>



          </category>




          </blogs>


          `,


	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
